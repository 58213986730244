import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Padding } from 'styled-components-spacing';
import { FormattedMessage } from 'react-intl';

import Row from './Row';
import Body from '../text/Body';
import Caption from '../text/Caption';
import colors from '../../constants/colors';
import { fetchEndpoint } from '../../api';
import LoadingSpinner from '../loading_spinner';
import { getQueryParam } from '../../utils';

const fadeIn = keyframes`
  from { opacity: 0 }
  to { opacity: 1 }
`;

const StyledTable = styled.table.attrs({
  className: 'table'
})`
  width: 100%;
  border: none;
  animation: ${fadeIn} linear .15s forwards;

  tr,
  td {
    border: none;
  }

  tr th {
    border-top: none;
    border-bottom-color: ${colors.brand.primary};
  }

  tbody tr:nth-of-type(odd) {
    background-color: rgba(60, 150, 125, 0.2)};
  }

  td,
  th {
    padding: 0.5rem 0.75rem;
  }
`;

class Highscore extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      highscore: []
    };

    this.fetchHighscore();
  }

  render() {
    let content;

    if (!this.state.loaded)
      content = (
        <Padding top={2}>
          <Body>
            <LoadingSpinner />
          </Body>
        </Padding>
      );
    else
      content = (
        <StyledTable>
          <thead>
            <tr>
              <th>
                <Caption color={colors.brand.primary}>
                  <FormattedMessage id="highscore_rank" />
                </Caption>
              </th>
              <th></th>
              <th>
                <Caption color={colors.brand.primary}>
                  <FormattedMessage id="highscore_player" />
                </Caption>
              </th>
              <th>
                <Caption color={colors.brand.primary}>
                  <FormattedMessage id="highscore_points" />
                </Caption>
              </th>
            </tr>
          </thead>
          <tbody>
            {this.state.highscore.map((entry, i) => (
              <Row key={i} entry={entry} rank={i + 1} />
            ))}
          </tbody>
        </StyledTable>
      );

    return <React.Fragment>{content}</React.Fragment>;
  }

  fetchHighscore() {
    const quiz_id = Number(getQueryParam('quiz'));
    fetchEndpoint(`/entries.json?quiz_id=${quiz_id}`).then(highscore => {
      let highscoreList = highscore;
      if (this.props.length !== undefined)
        highscoreList = highscoreList.slice(0, this.props.length);

      this.setState({
        loaded: true,
        highscore: highscoreList
      });
    });
  }
}

export default Highscore;
