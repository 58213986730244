//@flow
import React from 'react';

import styled, { keyframes } from 'styled-components';
import tree01 from './tree-01.png';
import tree02 from './tree-02.png';
import tree03 from './tree-03.png';
import tree04 from './tree-04.png';

const spriteAnimation = keyframes`
  from { background-position: 0% 100%; }
  to { background-position: 100% 0%; }
`;

const getSprite = name => {
  switch (name) {
    case 'tree01':
      return tree01;
    case 'tree02':
      return tree02;
    case 'tree03':
      return tree03;
    case 'tree04':
      return tree04;
    default:
      return tree01;
  }
};

export const animationPreloader: () => string = () => {
  const images = ['tree01', 'tree02', 'tree03', 'tree04'];
  const index = Math.floor(Math.random() * images.length);
  const image = new Image();
  image.src = getSprite(images[index]);
  return images[index];
};

const width = 700;
const height = 1080;
const totalWidth = 7712;
const steps = 10;

const Tree = styled.div`
  max-width: ${width}px;
  max-height: ${height}px;
  margin: auto;

  &:after {
    width: ${width}px;
    height: ${height}px;
    background-position: 0% 50%;
    background-size: ${(totalWidth / width) * 100}% 100%;
    background-image: url(${({ name }) => getSprite(name)});
    padding: 0;
    animation: ${spriteAnimation} 0.9s 0.2s steps(${steps}) forwards;
    content: '\\00a0';
    display: inline-block;
    width: 100%;
    height: 0;
    padding: 0 0 ${(height / width) * 100}% 0;
  }
`;

export default ({ className, name }: { className?: string, name: string }) => (
  <div className={className}>
    <Tree name={name} />
  </div>
);
