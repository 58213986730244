//@flow
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Padding } from 'styled-components-spacing';
import parse from 'html-react-parser';

import { setTree, resetPoints } from '../action_creators';
import { animationPreloader } from '../components/tree_animation';
import constants from '../constants';

import H1 from '../components/text/H1';
import ScreenContainer from '../components/layout/ScreenContainer';
import ScreenContent from '../components/layout/ScreenContent';
import ScreenStickyBottom from '../components/layout/ScreenStickyBottom';
import Body from '../components/text/Body';
import QuizLogo from '../images/holmen-quiz-logo.svg';
import { fetchEndpoint } from '../api';
import { getQueryParam } from '../utils';
type IntroPropTypes = {
  setTree: (tree: string) => {},
  className?: ?string,
  lang: string,
  quizDescription: string,
  numberOfEntries: string
};

class Intro extends React.Component<IntroPropTypes> {
  constructor(props) {
    super(props);

    this.state = {
      plantedTrees: '-'
    };

    fetchEndpoint('/results').then(({ all }) =>
      this.setState({ plantedTrees: all })
    );
  }

  componentDidMount() {
    const tree = animationPreloader();
    this.props.setTree(tree);
    this.props.resetPoints();
  }

  render() {
    const { quizDescription, numberOfEntries } = this.props;
    let lang;
    if (this.props.lang === 'sv')
      lang = {
        locale: 'en',
        label: 'English'
      };
    else
      lang = {
        locale: 'sv',
        label: 'Svenska'
      };

    return (
      <ScreenContainer
        background={constants.colors.brand.secondary}
        className={this.props.className}
      >
        <span
          onClick={() => this.props.setLang(lang.locale)}
          className="change-lang-link"
        >
          <span>{lang.label}</span>
        </span>
        <ScreenStickyBottom>
          <Padding top={8} bottom={1}>
            <Padding bottom={5}>
              <H1 className="highscore-title">Holmen Quiz</H1>
            </Padding>
            <Body>
              <p>
                {parse(
                  quizDescription.replace('{plantedTrees}', numberOfEntries)
                )}
              </p>
            </Body>
          </Padding>
          <Padding top={1} bottom={2}>
            <Link
              to={{ pathname: 'question', search: this.props.location.search }}
              className="start-link"
            >
              <FormattedMessage id="start_quiz_button" />
            </Link>
          </Padding>
          <Padding top={3} bottom={2}>
            <Link
              to={{ pathname: 'highscore', search: this.props.location.search }}
              className="highscore-link"
            >
              <span>
                <FormattedMessage id="show_highscore_button" />
              </span>
            </Link>
          </Padding>
        </ScreenStickyBottom>
      </ScreenContainer>
    );
  }
}
const mapStateToProps = ({ lang }) => ({
  lang
});

const mapDispatchToProps = (dispatch: *) =>
  bindActionCreators(
    {
      setTree,
      resetPoints
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(styled(Intro)`
  min-height: 100vh;

  ${ScreenContent} {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .intro-bg {
    border: 2px solid red;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .change-lang-link {
    cursor: pointer;
    position: absolute;
    top: ${constants.misc.screenContainerMarginX};
    right: ${constants.misc.screenContainerMarginX};
    color: ${constants.colors.brand.primary};
    z-index: 10;

    span {
      font-size: 0.85em;
      border-bottom: 1px solid rgba(255, 255, 255, 0.25);
      padding: 2px 0;
    }
  }

  ${ScreenStickyBottom} {
    .border-box {
      border-bottom: 2px solid ${constants.colors.brand.secondary};
    }
    .start-link {
      display: block;
      color: white;
      padding: 2rem;
      border-radius: 2px;
      background: ${constants.colors.brand.primary};
      color: ${constants.colors.brand.secondary};
      text-transform: uppercase;
      letter-spacing: 0.125rem;
      font-size: 0.75rem;
      font-weight: bold;
      text-align: center;
      line-height: 1;
    }

    .highscore-link {
      color: ${constants.colors.brand.primary};
      text-align: center;
      display: block;

      span {
        border-bottom: 1px solid rgba(0, 0, 0, 0.25);
        padding-bottom: 2px;
      }
    }
  }
`);
