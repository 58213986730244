//@flow
import React from 'react';
import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Padding } from 'styled-components-spacing';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

import { clear } from '../action_creators';
import constants from '../constants';
import ScreenContainer from '../components/layout/ScreenContainer';
import ScreenContent from '../components/layout/ScreenContent';
import ScreenStickyBottom from '../components/layout/ScreenStickyBottom';
import { getQueryParam, sendResults } from '../utils';
import H3 from '../components/text/H3';
import Body from '../components/text/Body';
import Caption from '../components/text/Caption';
import Button from '../components/button';
import tree01 from '../videos/tree01.mp4';
import tree02 from '../videos/tree02.mp4';
import tree03 from '../videos/tree03.mp4';

// List of tree animations
const treeAnimationVideos = {
  tree01,
  tree02,
  tree03
};

const Header = ({ points, className }) => (
  <div className={className}>
    <div className="row">
      <div className="col">
        <Padding right={3}>
          <H3>
            <FormattedMessage id="thanks_title" />
          </H3>
        </Padding>
        <Padding top={2} bottom={5}>
          <Body>
            <FormattedHTMLMessage tagName="p" id="thanks_body" />
          </Body>
        </Padding>
      </div>
    </div>
  </div>
);

const StyledHeader = styled(Header)``;

const HeaderCompleted = ({ points, className }) => (
  <div className={className}>
    <div className="row">
      <div className="col"></div>
    </div>
  </div>
);

const StyledHeaderCompleted = styled(HeaderCompleted)``;

class ThanksScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.username,
      completed: false,
      sending: false,
      hiddenButtons: true
    };
  }

  render() {
    const { className, history, clear, points, tree, location } = this.props;
    const { name, completed, sending, hiddenButtons } = this.state;

    if (completed)
      return (
        <ScreenContainer className={className}>
          <video
            className="forest-video"
            playsInline={true}
            autoPlay="autoplay"
            muted="muted"
          >
            <source src={treeAnimationVideos[tree]} type="video/mp4" />
          </video>
          <ScreenContent>
            <StyledHeaderCompleted />
          </ScreenContent>
          <ScreenStickyBottom>
            <Padding top={3} className="row">
              <div className={['col', hiddenButtons ? 'hidden' : ''].join(' ')}>
                <Padding bottom={2}>
                  <Link
                    to={{
                      pathname: '/highscore',
                      search: this.props.location.search
                    }}
                    className="btn"
                    style={{
                      backgroundColor: constants.colors.brand.secondary
                    }}
                  >
                    <FormattedMessage id="show_highscore_button" />
                  </Link>
                </Padding>
                <Button
                  className="btn"
                  onClick={() => {
                    clear();
                    history.push(`/${location.search}`);
                  }}
                  title={<FormattedMessage id="thanks_planted_play_button" />}
                />
              </div>
            </Padding>
          </ScreenStickyBottom>
        </ScreenContainer>
      );

    return (
      <ScreenContainer className={className}>
        <ScreenContent>
          <StyledHeader points={points} name={name} />
          <Padding vertical={2} className="row">
            <div className="col">
              <Caption color={constants.colors.brand.primary}>
                <FormattedMessage id="thanks_your_username" />
                <H3 color="white">{name}</H3>
              </Caption>
              <Padding top={1}></Padding>
            </div>
          </Padding>
        </ScreenContent>
        <ScreenStickyBottom>
          <Padding top={3} className="row">
            <div className="col">
              <Button
                onClick={() => this.submit()}
                title={
                  sending ? (
                    <FormattedMessage id="sending" />
                  ) : (
                    <FormattedMessage id="thanks_plant_button" />
                  )
                }
              />
            </div>
          </Padding>
        </ScreenStickyBottom>
      </ScreenContainer>
    );
  }

  submit() {
    const { points, tree, email } = this.props;
    const { name } = this.state;

    this.setState({ sending: true });
    const quiz_id = Number(getQueryParam('quiz'));
    sendResults({ name, points, email, tree, quiz_id })
      .then(_ => {
        this.setState({
          completed: true,
          sending: false
        });
        setTimeout(() => this.setState({ hiddenButtons: false }), 6000);
      })
      .catch(() => {})
      .then(() => this.setState({ sending: false }));
  }
}

const mapStateToProps = s => ({
  points: s.points,
  email: s.email,
  username: s.username,
  tree: s.tree
});
const mapDispatchToProps = (dispatch: *) =>
  bindActionCreators(
    {
      clear
    },
    dispatch
  );

const enhancer = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

const fadeIn = keyframes`
  from { opacity: 0 }
  to { opacity: 1 }
`;

const StyledResultsScreen = styled(enhancer(ThanksScreen))`
  position: relative;
  overflow: hidden;

  .forest-video {
    position: fixed;
    z-index: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
    animation: ${fadeIn} 1s linear forwards;
  }

  .btn {
    display: block;
    color: white;
    padding: 2rem;
    border: none;
    border-radius: 2px;
    background: ${constants.colors.brand.primary};
    text-transform: uppercase;
    letter-spacing: 0.125rem;
    font-size: 0.75rem;
    font-weight: bold;
    text-align: center;
    line-height: 1;
  }

  .col {
    transition: opacity 0.5s linear;
  }

  .hidden {
    opacity: 0;
  }
`;

export default StyledResultsScreen;
