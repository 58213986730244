//@flow
const gray100 = '#383B3E';
const gray80 = '#60666C';
const gray60 = '#A0A7AC';
const gray40 = '#D5D8DB';
const gray20 = '#E9EBED';
const gray10 = '#F4F5F6';

const colors = {
  gray100: gray100,
  gray80: gray80,
  gray60: gray60,
  gray40: gray40,
  gray20: gray20,
  gray10: gray10,

  brand: {
    primary: '#38E5D4',
    secondary: '#1E3856',
    error: '#FF6E2E',
    correct: '#38E5D4',
    black: '#042318',
    white: '#fff',
    light: gray10
  }
};

export default colors;
