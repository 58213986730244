//@flow
import React from 'react';
import styled from 'styled-components';

import constants from '../../constants';

const Checkmark = ({ className }) => (
  <span className={className}>
    <div className="stem" />
    <div className="kick" />
  </span>
);

const StyledCheckmark = styled(Checkmark)`
  display: inline-block;
  width: 22px;
  height: 22px;
  transform: rotate(45deg) translateX(-6px);

  .stem {
    position: absolute;
    width: 3px;
    height: 9px;
    background-color: ${constants.colors.brand.primary};
    left: 11px;
    top: 6px;
  }
  .kick {
    position: absolute;
    width: 3px;
    height: 3px;
    background-color: ${constants.colors.brand.primary};
    left: 8px;
    top: 12px;
  }
`;

const Checkbox = ({ className, onChange, checked = false }) => (
  <div className={className} onClick={onChange}>
    {checked ? <StyledCheckmark /> : null}
  </div>
);

export default styled(Checkbox)`
  border: 1px solid ${constants.colors.brand.primary};
  border-radius: ${constants.misc.borderRadiusSm};
  width: ${constants.spacing.base};
  height: ${constants.spacing.base};
  display: inline-block;
  overflow: hidden;
  cursor: pointer;
`;
