//@flow
import { keyframes } from 'styled-components';

export const questionButtonExit = keyframes`
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(15vw);
  }
  100% {
    transform: translateX(-100vw);
  }
`;

export const questionButtonEnter = keyframes`
  0% {
    transform: translateX(100vw);
  }
  50% {
    transform: translateX(-15vw);
  }
  100% {
    transform: translateX(0);
  }
`;

export const pulse = keyframes`
  0%    { opacity: 0.5; }
  50%   { opacity: 1; }
  100%  { opacity: 0.5; }
`;

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;
