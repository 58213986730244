import styled from 'styled-components';
import variables from '../../constants';

export default styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  padding: ${variables.misc.headerOffsetPadding}
    ${variables.misc.screenContainerMarginX}
    ${variables.misc.screenContainerMarginX};
  background-color: ${p =>
    p.background ? p.background : variables.colors.brand.secondary};
`;
