//@flow strict
import { fetchEndpoint } from './api';

export const getQueryParam = (name: string) => {
  const params = new URL(document.location).searchParams;
  return params.get(name);
};

export const stripUnit = (value: string): number => {
  const match = /[0-9]+\.?[0-9]*/.exec(value);
  return match ? Number(match[0]) : 0;
};

export const getUnit = (value: string): string => {
  const match = /[a-z]+|%/i.exec(value);
  return match ? match[0] : '';
};

export const multiply = (value: string, scalar: number) => {
  return stripUnit(value) * scalar + getUnit(value);
};

export const sendResults = async opts => {
  // Detect user location
  let country = await fetch('https://extreme-ip-lookup.com/json/', {
    method: 'GET'
  })
    .then(d => d.json())
    .then(data => data.countryCode.toLowerCase())
    .catch(() => null);

  // Fallback to navigator language
  if (!country)
    country = (navigator.language || navigator.userLanguage || 'se')
      .replace(/^.+-([a-z]{2})$/i, '$1')
      .toLowerCase();

  return fetchEndpoint(
    '/entries',
    {
      ...opts,
      country
    },
    {
      method: 'POST'
    }
  ).catch(r => {
    console.log(r);
  });
};

const words = { adjectives: [], nouns: [] };

export const fetchPlayerNameWords = () => {
  return fetchEndpoint('/words').then(({ adjectives, nouns }) => {
    words.adjectives = adjectives;
    words.nouns = nouns;
  });
};

export const generateName = (): string => {
  const { adjectives, nouns } = words;

  if (!adjectives.length || !nouns.length) return 'James Bond 007';

  const random = (upper: number) => Math.floor(Math.random() * upper);

  const number = Math.round(Math.random() * 100);
  const adjective = adjectives[random(adjectives.length)];
  const noun = nouns[random(nouns.length)];

  return `${adjective} ${noun} ${number}`;
};

export const shuffleArray = <T>(a: Array<T>): Array<T> => {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const iVal = a[i];
    a[i] = a[j];
    a[j] = iVal;
  }
  return a;
};
