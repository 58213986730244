//@flow
import React, { Component } from 'react';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter, MemoryRouter, Route, Switch } from 'react-router-dom';
import { createStore } from 'redux';
import { Provider } from 'react-redux';

import { setLang } from './action_creators';
import rootReducer from './reducers';
import theme from './styled-compnents-theme';
import ConnectedIntlProvider, { setLocale } from './intl';
import Intro from './screens/Intro';
import QuestionScreen from './screens/QuestionScreen.js';
import DetailsScreen from './screens/DetailsScreen.js';
import ResultsScreen from './screens/ResultsScreen.js';
import ThanksScreen from './screens/ThanksScreen.js';
import HighscoreScreen from './screens/HighscoreScreen';
import PresentationScreen from './screens/PresentationScreen';
import Header from './components/header';
import LoadingSpinner from './components/loading_spinner';
import { fetchEndpoint } from './api';
import { getQueryParam } from './utils';

let store;
let Router = MemoryRouter;
if (process.env.NODE_ENV === 'development') {
  // I use router so that we can navigate to a view in development,
  // this makes it so much easier to just hit refresh instead of walking trough the app
  Router = BrowserRouter;

  // Use redux devtools in development
  store = createStore(
    rootReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );
} else {
  store = createStore(rootReducer);
}

if (/^\/presentation/.test(window.location.pathname)) Router = BrowserRouter;

class App extends Component {
  constructor(props) {
    super(props);

    this.loadData();
    this.state = {
      loaded: false,
      quiz: {}
    };
  }

  render() {
    if (!this.state.loaded) {
      return <LoadingSpinner />;
    }

    // Map questions to old format
    let lang = store.getState().lang;
    const quiz = this.state.quiz;
    const responseTime = quiz.response_time;
    const maxPointsPerQuestion = quiz.max_points_per_question;
    const numberOfEntries = quiz.total_number_of_entries;
    const quizDescription =
      lang === 'sv' ? quiz.description_sv : quiz.description_en;
    const questions = quiz.questions.map(q => {
      // Default to swedish if the requested translation doesn't exist
      if (!q[`title_${lang}`]) lang = 'sv';

      return {
        question: q[`title_${lang}`],
        answers: q.answers.map(a => ({
          correct: a.correct,
          title: a[`text_${lang}`]
        }))
      };
    });

    return (
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <ConnectedIntlProvider>
            <Router>
              <main>
                <Header />
                <Switch>
                  <Route
                    path="/presentation/:length?"
                    render={props => (
                      <PresentationScreen
                        {...props}
                        setLang={lang => this.setLang(lang)}
                        lang={lang}
                        quizDescription={quizDescription}
                        numberOfEntries={numberOfEntries}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/"
                    render={props => (
                      <Intro
                        {...props}
                        setLang={lang => this.setLang(lang)}
                        quizDescription={quizDescription}
                        numberOfEntries={numberOfEntries}
                      />
                    )}
                  />
                  <Route path="/results" component={ResultsScreen} />
                  <Route path="/details" component={DetailsScreen} />
                  <Route path="/form" component={ThanksScreen} />
                  <Route path="/highscore" component={HighscoreScreen} />
                  <Route
                    path="/question"
                    render={props => (
                      <QuestionScreen
                        {...props}
                        responseTime={responseTime * 1000}
                        questions={questions}
                        maxPointsPerQuestion={maxPointsPerQuestion}
                      />
                    )}
                  />
                </Switch>
              </main>
            </Router>
          </ConnectedIntlProvider>
        </ThemeProvider>
      </Provider>
    );
  }

  loadData() {
    let quiz = {};
    const localeRequest = fetchEndpoint('/translations').then(locale =>
      setLocale(locale)
    );
    let quizParam = getQueryParam('quiz');
    let endpoint = `/quizzes/${quizParam}`;
    if (!quizParam) {
      endpoint = '/quizzes';
    }
    const quizRequest = fetchEndpoint(endpoint).then(
      quizRes => (quiz = quizRes)
    );
    Promise.all([localeRequest, quizRequest]).then(() => {
      if (!quizParam) window.location = `/?quiz=${quiz.id}`;
      this.setState({
        loaded: true,
        quiz
      });
    });
  }

  setLang(lang) {
    store.dispatch(setLang(lang));
    this.forceUpdate();
  }
}

export default App;
