//@flow
import React from 'react';
import ReactCSSTransitionReplace from 'react-css-transition-replace';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { type RouterHistory } from 'react-router-dom';
import {
  type Question as QuestionType,
  type Answer as AnswerType
} from '../types/QuestionTypes';

import { addPoints } from '../action_creators';
import { getQueryParam, shuffleArray } from '../utils';
import QuestionIndicator from '../components/question_indicator';
import Question from '../components/question';
import ScreenContainer from '../components/layout/ScreenContainer';
import ScreenContent from '../components/layout/ScreenContent';
import ScreenStickyBottom from '../components/layout/ScreenStickyBottom';

type QuestionPropTypes = {
  questions: Array<QuestionType>,
  responseTime: number,
  maxPointsPerQuestion: number,
  history: RouterHistory,
  addPoints: number => *
};

type QuestionStateType = {
  currentStep: number,
  timeLeft: number,
  answer: ?AnswerType,
  timedOut: boolean,
  questions: Array<QuestionType>
};

const transitionTime = 3000;
let answerTime = 10000; // 10s
const intervalTime = 100;
const showAnswerTime = 2000;
export const pointsAnimationDuration = 1500;

const initialState: QuestionStateType = {
  currentStep: 0,
  timeLeft: answerTime,
  answer: null,
  timedOut: false,
  questions: []
};

class QuestionScreen extends React.Component<
  QuestionPropTypes,
  QuestionStateType
> {
  interval: IntervalID;
  nextQuestionTimeout: TimeoutID;

  constructor(props: QuestionPropTypes) {
    super(props);
    const quizParam = getQueryParam('quiz');
    this.state = {
      ...initialState,
      timeLeft: this.props.responseTime,
      quiz: quizParam
    };

    const questions = Object.assign([], this.props.questions);
    this.state.questions = shuffleArray(questions).map(question => ({
      ...question,
      answers: shuffleArray(question.answers)
    }));
    answerTime = this.props.responseTime;
  }

  decreaseTimer() {
    const timeLeft = this.state.timeLeft - intervalTime;
    if (timeLeft >= 0 && !this.state.answer) {
      this.setState({ timeLeft });
    } else if (timeLeft <= 0 && !this.nextQuestionTimeout) {
      this.gotoNextQuestion(true);
    }
  }

  componentDidMount() {
    this.interval = setInterval(_ => this.decreaseTimer(), intervalTime);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    clearTimeout(this.nextQuestionTimeout);
  }

  percentageLeft() {
    return Math.min(100, (this.state.timeLeft / answerTime) * 100);
  }

  getCurrentQuestion() {
    return this.state.questions[this.state.currentStep];
  }

  gotoNextQuestion = (timeout: boolean = false) => {
    this.setState({ timedOut: timeout });
    clearTimeout(this.nextQuestionTimeout);
    this.nextQuestionTimeout = setTimeout(() => {
      delete this.nextQuestionTimeout;
      if (this.state.questions.length > this.state.currentStep + 1) {
        this.setState({
          currentStep: this.state.currentStep + 1,
          timeLeft: answerTime + transitionTime,
          answer: null,
          timedOut: false
        });
      } else {
        this.props.history.push(`/results/${this.props.location.search}`);
      }
    }, showAnswerTime);
  };

  calculatePoints = () => {
    return Math.round(
      this.props.maxPointsPerQuestion *
        Math.min(1, this.state.timeLeft / answerTime)
    );
  };
  answer = (answer: AnswerType) => {
    if (this.state.answer === null && this.state.timedOut === false) {
      this.setState({ answer });
      this.props.addPoints(answer.correct ? this.calculatePoints() : 0);
      this.gotoNextQuestion();
    }
  };

  render() {
    return (
      <ScreenContainer>
        <ScreenContent>
          <ReactCSSTransitionReplace
            transitionName="change-question"
            transitionEnterTimeout={transitionTime}
            transitionLeaveTimeout={transitionTime}
          >
            <Question
              {...this.getCurrentQuestion()}
              key={this.state.currentStep}
              onAnswer={this.answer}
              currentAnswer={this.state.timedOut || this.state.answer}
              percentage={this.percentageLeft()}
            />
          </ReactCSSTransitionReplace>
        </ScreenContent>
        <ScreenStickyBottom>
          <div className="row">
            <div className="col">
              <QuestionIndicator
                currentStep={this.state.currentStep + 1}
                steps={this.state.questions.length}
              />
            </div>
          </div>
        </ScreenStickyBottom>
      </ScreenContainer>
    );
  }
}

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch: *) =>
  bindActionCreators(
    {
      addPoints
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestionScreen);
