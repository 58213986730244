//@flow
import spacing from './spacing';
import colors from './colors';

const PrimaryFont = 'IBM Plex Sans';
const letterSpacingBase = '0.0625em';

type TypographyType = {
  color: ?string,
  bold: ?boolean,
  normal: ?boolean,
  italic: ?boolean,
  align: ?string,
  textTransform: ?string
};

export default {
  letterSpacingBase: letterSpacingBase,

  base: (p: TypographyType) => ({
    fontFamily: `${PrimaryFont}, sans-serif`,
    color: p.color ? p.color : colors.brand.white,
    fontWeight: p.bold ? 'bold' : p.normal ? 'normal' : 'inherit',
    fontStyle: p.italic ? 'italic' : 'normal',
    textAlign: p.align ? p.align : 'inherit',
    fontSize: 'inherit',
    textTransform: p.textTransform ? p.textTransform : 'inherit',
    letterSpacing: p.textTransform ? letterSpacingBase : 'inherit'
  }),

  heading: {
    marginBottom: 0,
    lineHeight: 1,
    fontFamily: `'Holmen Tree', sans-serif`,
    color: colors.brand.primary
  },

  h1: {
    fontSize: spacing.xxxl
  },

  h2: {
    fontSize: spacing.xxl,
    textTransform: 'uppercase',
    lineHeight: 0.875
  },

  h3: {
    fontSize: spacing.xl
  },

  h4: {
    fontSize: spacing.lg
  },

  body: {
    fontSize: spacing.base,
    lineHeight: 1.25
  },

  caption: {
    fontSize: spacing.sm,
    lineHeight: 1.5,
    display: 'inline-block'
  }
};
