export function fetchEndpoint(
  endpoint,
  data,
  customOptions = { method: 'GET' }
) {
  if (customOptions.method === 'POST')
    customOptions.body = JSON.stringify(data);

  const api = process.env.REACT_APP_API_HOST || 'http://localhost:5000/api';
  return fetch(api + endpoint, {
    headers: {
      'Content-Type': 'application/json'
    },
    data,
    ...customOptions
  }).then(response => response.json());
}
