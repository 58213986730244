import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import constants from '../constants';
import H1 from '../components/text/H1';
import Highscore from '../components/highscore';
import ScreenContainer from '../components/layout/ScreenContainer';
import ScreenContent from '../components/layout/ScreenContent';
import ScreenStickyBottom from '../components/layout/ScreenStickyBottom';
import Button from '../components/button';

const HighscoreScreen = ({ className, location, history }) => (
  <ScreenContainer className={className}>
    <ScreenContent className="content">
      <H1 className="highscore-title">
        <FormattedMessage id="highscore_title" />
      </H1>
      <Highscore length={10} />
    </ScreenContent>
    <ScreenStickyBottom className="sticky-bottom">
      <Button
        className="btn"
        onClick={() => {
          history.push(`/${location.search}`);
        }}
        title={<FormattedMessage id="back_to_start_button" />}
      />
    </ScreenStickyBottom>
  </ScreenContainer>
);
const StyledHighscoreScreen = styled(HighscoreScreen)`
  overflow-x: hidden;

  .back-button {
    display: block;
    color: white;
    padding: 2rem;
    border-radius: 2px;
    background: ${constants.colors.brand.primary};
    color: ${constants.colors.brand.secondary};
    text-transform: uppercase;
    letter-spacing: 0.125rem;
    font-size: 0.75rem;
    font-weight: bold;
    text-align: center;
    line-height: 1;
  }

  .content {
    padding-bottom: 6rem;
  }

  .highscore-title {
    margin-bottom: 2rem;
  }

  .sticky-bottom {
    position: fixed;
    bottom: 0;
    width: 100%;
    max-width: 33rem;
    padding: 0 1.5rem 1.5rem 1.5rem;
    background: ${constants.colors.brand.secondary};
  }
`;

export default StyledHighscoreScreen;
