//@flow
import {
  ADD_POINTS,
  SET_EMAIL,
  SET_USERNAME,
  CLEAR,
  SET_TREE,
  RESET_POINTS,
  type ReduxRootState,
  type Action,
  SET_LANG,
  SET_QUIZ
} from '../types/ReduxTypes';

const navigatorLang = navigator.language.split(/[-_]/)[0];
const initialState: ReduxRootState = {
  lang: localStorage.getItem('lang') || navigatorLang || 'sv',
  points: 0,
  username: '',
  quiz: null,
  email: '',
  tree: 'tree01'
};

const reducer: (ReduxRootState, Action) => ReduxRootState = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SET_LANG:
      localStorage.setItem('lang', action.lang);
      return Object.assign({}, state, { lang: action.lang });
    case ADD_POINTS:
      return Object.assign({}, state, { points: state.points + action.points });
    case RESET_POINTS:
      return Object.assign({}, state, { points: 0 });
    case CLEAR:
      return {
        ...initialState,
        lang: state.lang
      };
    case SET_EMAIL:
      return Object.assign({}, state, { email: action.email });
    case SET_USERNAME:
      return Object.assign({}, state, { username: action.username });
    case SET_QUIZ:
      return Object.assign({}, state, { quiz: action.quiz });
    case SET_TREE:
      return Object.assign({}, state, { tree: action.tree });
    default:
      return state;
  }
};

export default reducer;
