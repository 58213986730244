//@flow
import React from 'react';
import styled, { type ReactComponentStyled } from 'styled-components';

import Body from '../text/Body';
import constants from '../../constants';
import { transparentize } from 'polished';

export type StateType =
  | 'UNANSWERED'
  | 'ANSWERED'
  | 'WRONG'
  | 'CORRECT'
  | 'CORRECT_WRONG_ANSWER';

export type Props = {
  className: ?string,
  state: StateType,
  onClick: SyntheticEvent<HTMLDivElement>,
  title: string,
  questionName: string
};

const AnswerButton = (props: Props) => (
  <div onClick={props.onClick} className={props.className}>
    <Body
      bold
      textTransform="uppercase"
      color={constants.colors.brand.white}
      className="question-name"
    >
      {props.questionName}
    </Body>
    <Body color={constants.colors.brand.white}>{props.title}</Body>
  </div>
);

const getBackgroundColor = (state: StateType): string => {
  switch (state) {
    case 'UNANSWERED':
      return 'transparent';
    case 'WRONG':
      return constants.colors.brand.error;
    case 'CORRECT':
      return constants.colors.brand.correct;
    case 'CORRECT_WRONG_ANSWER':
      return transparentize(0.5, constants.colors.brand.correct);
    default:
      return 'transparent';
  }
};

const getBorderColor = (state: StateType): string => {
  switch (state) {
    case 'WRONG':
      return constants.colors.brand.error;
    default:
      return constants.colors.brand.correct;
  }
};

const StyledButton: ReactComponentStyled<Props> = styled(AnswerButton)`
  display: flex;
  background: ${constants.colors};
  align-items: center;
  line-height: 1;
  padding: ${constants.spacing.sm};
  margin-bottom: ${constants.spacing.sm};
  border: 2px solid ${(p: Props) => getBorderColor(p.state)};
  background: ${(p: Props) => getBackgroundColor(p.state)};
  cursor: ${p => (p.state === 'UNANSWERED' ? 'pointer' : 'inherited')};
  opacity: 1;
  transition: background 250ms ease;

  .question-name {
    margin-right: ${constants.spacing.md};
    margin-left: ${constants.spacing.xs};
  }
`;
StyledButton.defaultProps = {
  state: 'UNANSWERED'
};
export default StyledButton;
