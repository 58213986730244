//@flow
import React from 'react';
import styled, { type ReactComponentStyled } from 'styled-components';

import Body from '../text/Body';
import constants from '../../constants';

export type Props = {
  className: ?string,
  onClick: SyntheticEvent<HTMLDivElement>,
  title: string,
  disabled: boolean
};

const AnswerButton = (props: Props) => (
  <div
    onClick={props.disabled ? null : props.onClick}
    className={props.className}
  >
    <Body color={constants.colors.brand.white}>{props.title}</Body>
  </div>
);

const StyledButton: ReactComponentStyled<Props> = styled(AnswerButton)`
  display: ${p => (p.inlineBlock ? 'inline-block' : 'block')};
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  text-align: center;
  line-height: 1;
  transition: all 250ms ease;
  border: 2px solid
    ${({ disabled }) =>
      disabled ? constants.colors.gray60 : constants.colors.brand.primary};
  cursor: ${({ disabled }) => (disabled ? 'inherit' : 'pointer')};
  padding: ${constants.spacing.sm};
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};

  &:hover {
    background: ${({ disabled }) =>
      !disabled ? constants.colors.brand.primary : 'inherit'};
  }
`;
export default StyledButton;
