import React from 'react';
import colors from '../../constants/colors';
import styled, { keyframes } from 'styled-components';
import { fetchEndpoint } from '../../api';
import { getQueryParam } from '../../utils';
import WheelComponent from 'react-wheel-of-prizes';
import JSConfetti from 'js-confetti';

const fadeIn = keyframes`
  from { opacity: 0 }
  to { opacity: 1 }
`;

const centerDiv = styled.div.attrs({
  className: 'centerDiv'
})`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

class LotteryWheel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      segments: [],
      segColors: []
    };
    this.fetchHighscoreData();
  }

  render() {
    const jsConfetti = new JSConfetti();

    const onFinished = winner => jsConfetti.addConfetti();

    if (this.state.loaded)
      return (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-30%, -35%)',
            zIndex: 50
          }}
        >
          <WheelComponent
            segments={this.state.segments}
            segColors={this.state.segColors}
            onFinished={onFinished}
            primaryColor={colors.brand.black}
            contrastColor={colors.brand.light}
            buttonText=""
            isOnlyOnce={true}
            size={290}
            upDuration={Math.floor(Math.random() * 80) + 40}
            downDuration={Math.floor(Math.random() * 300) + 150}
            fontFamily="Arial"
          />
        </div>
      );
    else return <></>;
  }
  fetchHighscoreData() {
    const quiz_id = Number(getQueryParam('quiz'));
    fetchEndpoint(`/entries.json?quiz_id=${quiz_id}`).then(highscore => {
      console.log(highscore);
      const segments = highscore.map(entry => entry.name);
      const segColors = highscore.map((entry, i) =>
        (i + 1) % 3 === 0
          ? colors.brand.primary
          : (i + 1) % 2 === 0
          ? colors.brand.secondary
          : colors.brand.black
      );

      this.setState({
        loaded: true,
        segments: segments,
        segColors: segColors
      });
    });
  }
}

export default LotteryWheel;
