//@flow
import React from 'react';
import styled, {
  type ReactComponentStyled,
  injectGlobal
} from 'styled-components';
import { connect } from 'react-redux';
import $ from 'jquery';
import { FormattedMessage } from 'react-intl';

import constants from '../../constants';
import { type Answer } from '../../types/QuestionTypes';
import Countdown from '../countdown';
import { Padding } from 'styled-components-spacing';
import H3 from '../text/H3';
import Caption from '../text/Caption';
import AnswerButton, { type StateType } from '../answer_button';
import {
  questionButtonExit,
  questionButtonEnter
} from '../../styledAnimations';
import { pointsAnimationDuration } from '../../screens/QuestionScreen';

const questionNames = ['A', 'B', 'C', 'D'];
export type QuestionProps = {
  percentage: number,
  question: string,
  onAnswer: Answer => void,
  answers: Array<Answer>,
  currentAnswer?: ?Answer | true,
  className?: ?string,
  playerPoints: number
};

type QuestionState = {
  points: number
};

const getState = (answer: Answer, currentAnswer: ?Answer): StateType => {
  if (currentAnswer === undefined || currentAnswer === null)
    return 'UNANSWERED';
  if (currentAnswer.correct && answer === currentAnswer) return 'CORRECT';
  if (!currentAnswer.correct && answer === currentAnswer) return 'WRONG';
  if (answer.correct) return 'CORRECT_WRONG_ANSWER';
  return 'ANSWERED';
};

const getTimeoutState = (answer: Answer): StateType => {
  if (answer.correct) return 'CORRECT_WRONG_ANSWER';
  return 'ANSWERED';
};

const QuestionHeader = ({
  question,
  percentage,
  className,
  playerPoints
}: {
  question: string,
  percentage: number,
  className: string,
  playerPoints: number
}) => (
  <div className={className}>
    <Padding bottom={5} className="row">
      <div className="col d-flex align-items-center justify-content-between">
        <Countdown percentage={percentage} />
        <div style={{ textAlign: 'right' }}>
          <Caption color={constants.colors.brand.primary}>
            <FormattedMessage id="question_your_points" />
          </Caption>
          <span
            style={{
              display: 'block',
              color: '#fff',
              fontWeight: 'bold',
              lineHeight: '1.2'
            }}
          >
            {playerPoints}
          </span>
        </div>
      </div>
    </Padding>
    <Padding bottom={5} className="row">
      <div className="col">
        <H3>{question}</H3>
      </div>
    </Padding>
  </div>
);

const offset = 100;
const duration = 800;
const wait = 1000;

const StyledQuestionHeader = styled(QuestionHeader)``;

class Question extends React.Component<QuestionProps, QuestionState> {
  constructor(props) {
    super(props);
    this.state = {
      points: props.playerPoints
    };
  }

  render() {
    const {
      percentage,
      question,
      answers,
      onAnswer,
      currentAnswer,
      className
    } = this.props;

    return (
      <div className={className}>
        <StyledQuestionHeader
          question={question}
          percentage={percentage}
          playerPoints={this.state.points}
        />
        <div className="row">
          <div className="col">
            {answers.map((answer, i) => (
              <AnswerButton
                state={
                  currentAnswer === true
                    ? getTimeoutState(answer)
                    : getState(answer, currentAnswer)
                }
                key={answer.title}
                onClick={() => onAnswer(answer)}
                questionName={questionNames[i]}
                title={answer.title}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.playerPoints !== prevProps.playerPoints)
      this.onPointsUpdated(prevProps.playerPoints, this.props.playerPoints);
  }

  onPointsUpdated(oldPoints, newPoints) {
    const reactContext = this;
    const easeInOutCubic = t =>
      t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;

    $({ animatedPoints: oldPoints }).animate(
      { animatedPoints: newPoints },
      {
        duration: pointsAnimationDuration,
        step: function() {
          const diff = newPoints - oldPoints;
          const t = (this.animatedPoints - oldPoints) / diff;

          reactContext.setState({
            points: Math.floor(oldPoints + diff * easeInOutCubic(t))
          });
        },
        complete: function() {
          reactContext.setState({ points: this.animatedPoints });
        }
      }
    );
  }
}

// Connect redux state
const mapStateToProps = ({ points }) => ({ playerPoints: points });
const ConnectedQuestion = connect(mapStateToProps)(Question);

const StyledQuestion: ReactComponentStyled<QuestionProps> = styled(
  ConnectedQuestion
)`
  &.change-question-enter {
    ${StyledQuestionHeader} {
      opacity: 0.01;
    }
    ${AnswerButton} {
      transform: translateX(100vw);
    }
  }
  &.change-question-enter-active {
    ${StyledQuestionHeader} {
      opacity: 1;
      transition: opacity 500ms ease-in ${wait}ms;
    }
    ${AnswerButton}:nth-child(1) {
      animation: ${questionButtonEnter} ${duration}ms ease
        ${0 * offset + duration + wait}ms forwards;
    }
    ${AnswerButton}:nth-child(2) {
      animation: ${questionButtonEnter} ${duration}ms ease
        ${1 * offset + duration + wait}ms forwards;
    }
    ${AnswerButton}:nth-child(3) {
      animation: ${questionButtonEnter} ${duration}ms ease
        ${2 * offset + duration + wait}ms forwards;
    }
    ${AnswerButton}:nth-child(4) {
      animation: ${questionButtonEnter} ${duration}ms ease
        ${3 * offset + duration + wait}ms forwards;
    }
  }
  &.change-question-leave {
    ${StyledQuestionHeader} {
      opacity: 1;
    }
  }
  &.change-question-leave-active {
    ${StyledQuestionHeader} {
      opacity: 0.01;
      transition: opacity 500ms ease-out;
    }
    ${AnswerButton}:nth-child(1) {
      animation: ${questionButtonExit} ${duration}ms ease ${offset * 0}ms
        forwards;
    }
    ${AnswerButton}:nth-child(2) {
      animation: ${questionButtonExit} ${duration}ms ease ${offset * 1}ms
        forwards;
    }
    ${AnswerButton}:nth-child(3) {
      animation: ${questionButtonExit} ${duration}ms ease ${offset * 2}ms
        forwards;
    }
    ${AnswerButton}:nth-child(4) {
      animation: ${questionButtonExit} ${duration}ms ease ${offset * 3}ms
        forwards;
    }
  }
`;

injectGlobal`
.change-question-height {
    transition: height 0.200s ease-in-out ${duration}ms;
  }
`;
export default StyledQuestion;
