import React from 'react';
import styled, { keyframes } from 'styled-components';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import parse from 'html-react-parser';

import constants from '../constants';
import ScreenContainer from '../components/layout/ScreenContainer';
import Highscore from '../components/highscore';
import ScreenContent from '../components/layout/ScreenContent';
import H1 from '../components/text/H1';
import { fetchEndpoint } from '../api';

import QRCode from 'react-qr-code';
import forestVideo from '../videos/forest-loop.mp4';
import tree01 from '../videos/tree01.mp4';
import tree02 from '../videos/tree02.mp4';
import tree03 from '../videos/tree03.mp4';
import LotteryWheel from '../components/lottery_wheel';

// Animation queue
let queue = [];
// List of tree animations
const treeAnimationVideos = {
  tree01,
  tree02,
  tree03
};

class PresentationScreen extends React.Component {
  fetchInterval = null;
  fetchIntervalTime = 10000;

  highscoreComponent = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      currentTreeAnimation: null,
      showHighscore: true,
      lastFetchTimestamp: this.currentTimestamp()
    };

    this.videoRefs = Object.keys(treeAnimationVideos).reduce(
      (refs, treeName) => {
        refs[treeName] = React.createRef();
        return refs;
      },
      {}
    );

    // TODO: Remove after test
    window.runAnimation = treeId => this.addToQueue([treeId]);
  }

  currentTimestamp() {
    return Math.floor(Date.now() / 1000);
  }

  componentDidMount() {
    this.fetchInterval = setInterval(() => {
      fetchEndpoint(
        `/entries/feed?since=${this.state.lastFetchTimestamp}`
      ).then(entries => this.addToQueue(entries));

      this.setState({ lastFetchTimestamp: this.currentTimestamp() });
    }, this.fetchIntervalTime);
  }

  componentWillUnmount() {
    clearTimeout(this.fetchInterval);
  }

  render() {
    const {
      className,
      match,
      location,
      quizDescription,
      numberOfEntries
    } = this.props;

    let highscoreClass = '';
    let containerClass = '';

    if (!this.state.showHighscore) {
      containerClass = 'active';
      highscoreClass = 'hidden';
    }

    const treeVideos = Object.keys(treeAnimationVideos).reduce(
      (content, treeName) => {
        const animatingEntry = this.state.currentTreeAnimation;

        content.push(
          <div
            key={treeName}
            className={[
              'video',
              'tree-animation',
              animatingEntry && animatingEntry.tree === treeName ? 'active' : ''
            ].join(' ')}
          >
            <video
              ref={this.videoRefs[treeName]}
              playsInline={true}
              autoPlay={false}
              muted="muted"
              onEnded={() => this.onAnimationEnded()}
            >
              <source
                key="video"
                src={treeAnimationVideos[treeName]}
                type="video/mp4"
              />
            </video>
          </div>
        );

        return content;
      },
      []
    );

    let treePlayerName = null;
    if (this.state.currentTreeAnimation) {
      const { name, country } = this.state.currentTreeAnimation;
      let flag;

      if (country) {
        try {
          // The try/catch prevents errors if flag doesn't exist
          const flagImg = require(`../images/flags/${country}.png`);
          if (flagImg) flag = <img alt="Player flag" src={flagImg} />;
        } catch (e) {}
      }

      treePlayerName = (
        <div className="animation-player-name">
          <span>
            {flag}
            <span>{name}</span>
          </span>
        </div>
      );
    }

    let lang;
    if (this.props.lang === 'sv')
      lang = {
        locale: 'en',
        label: 'English'
      };
    else
      lang = {
        locale: 'sv',
        label: 'Svenska'
      };

    return (
      <ScreenContainer className={className}>
        <span
          className="change-lang-link"
          onClick={() => this.props.setLang(lang.locale)}
        >
          <span>{lang.label}</span>
        </span>
        <div className={['video-container', containerClass].join(' ')}>
          <div className="video">
            <video
              className="forest"
              playsInline={true}
              autoPlay={true}
              loop="loop"
              muted="muted"
            >
              <source key="video" src={forestVideo} type="video/mp4" />
            </video>
          </div>
          {treeVideos}
          {treePlayerName}
        </div>
        <ScreenContent className="content">
          <div className={['idle-container', highscoreClass].join(' ')}>
            <H1 className="title">
              <FormattedMessage id="presentation_title" />
            </H1>
            <div className="idle-content">
              <div className="body-text">
                <p>
                  {parse(
                    quizDescription.replace('{plantedTrees}', numberOfEntries)
                  )}
                </p>
                <a href={`/${location.search}`}>
                  <QRCode
                    style={{ marginTop: '25px' }}
                    size={180}
                    fgColor="white"
                    bgColor={'transparent'}
                    value={`${window.location.host}/${location.search}`}
                  />
                  {/* <img className="qr-code" src={qrImage} alt="Link to quiz" /> */}
                </a>
                {/* <LotteryWheel /> */}
              </div>
              <div className="highscore">
                <Highscore
                  ref={this.highscoreComponent}
                  length={match.params.length || 10}
                />
              </div>
            </div>
          </div>
        </ScreenContent>
      </ScreenContainer>
    );
  }

  runAnimation(entry) {
    const { tree } = entry;
    if (!this.videoRefs[tree]) return;

    this.setState({ currentTreeAnimation: entry });
    setTimeout(() => {
      this.setState({ showHighscore: false });
      this.videoRefs[tree].current.play();

      // Fetch new highscores
      this.highscoreComponent.current.fetchHighscore();
    }, 500);
  }

  playNextAnimation() {
    if (!queue.length || this.state.currentTreeAnimation !== null) return;

    const [first, ...others] = queue;
    queue = others;

    this.runAnimation(first);
  }

  addToQueue(trees) {
    queue = [...queue, ...trees];
    this.playNextAnimation();
  }

  onAnimationEnded() {
    this.setState({ showHighscore: true });
    setTimeout(() => {
      this.setState({ currentTreeAnimation: null }, () =>
        this.playNextAnimation()
      );
    }, 1000);
  }
}

const playerNameAnimation = keyframes`
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
`;

const playerNameOutAnimation = keyframes`
  0% {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
  100% {
    transform: scale3d(0, 0, 0);
    opacity: 0;
  }
`;

const StyledPresentationScreen = styled(PresentationScreen)`
  overflow: hidden;
  background: black;

  .video-container {
    transition: opacity 0.7s ease-out;

    &:not(.active) {
      opacity: 0.3;
    }
  }

  .content {
    min-height: calc(100vh - 170px);
    z-index: 1;
    display: flex;
    align-items: center;
    margin-bottom: 3rem;
    max-width: 60rem;
  }

  .video {
    position: fixed;
    z-index: 0;
    min-width: 100%;
    min-height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    transition: opacity 0.5s ease-out;

    video {
      min-width: 100vw;
      min-height: 101vh;
    }

    &.tree-animation:not(.active) {
      opacity: 0;
    }
  }

  .idle-container {
    transition: opacity 0.7s linear;
    transform: scale(1.3);

    & * {
      font-smooth: never;
    }

    &.hidden {
      opacity: 0;
    }

    .title {
      width: 100%;
    }

    .idle-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 2rem;

      & > * {
        width: 47%;
      }

      .body-text {
        color: #fff;
        margin-top: 0.75rem;
      }

      .qr-code {
        margin-top: 1rem;
        transform: translateX(-0.8rem);
        width: 13rem;
        height: 13rem;
      }

      .highscore {
        transform: scale(1.1) translateY(-0.3rem);
        margin-bottom: 1rem;
      }
    }
  }

  .animation-player-name {
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 26vh;
    position: fixed;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    & > span {
      background: rgba(0, 0, 0, 0.4);
      color: #fff;
      font-size: 1.5rem;
      padding: 0 1.5rem;
      opacity: 0;
      animation: ${playerNameAnimation} 0.75s forwards 5.7s,
        ${playerNameOutAnimation} 0.3s forwards 9.5s;
      border-radius: 0.5rem;

      &::before {
        content: ' ';
        position: absolute;
        bottom: 100%; /* At the top of the tooltip */
        left: 50%;
        margin-left: -0.375rem;
        border-width: 0.75rem;
        border-style: solid;
        border-color: transparent transparent rgba(0, 0, 0, 0.4) transparent;
      }

      img {
        margin-right: 1rem;
        height: 100%;
      }

      & > span {
        height: 3.5rem;
        display: inline-flex;
        align-items: center;
      }
    }
  }

  .change-lang-link {
    cursor: pointer;
    position: absolute;
    top: ${constants.misc.screenContainerMarginX};
    right: ${constants.misc.screenContainerMarginX};
    color: ${constants.colors.brand.black};
    z-index: 10;
    color: rgba(255, 255, 255, 0.75);

    span {
      font-size: 0.85em;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      padding: 2px 0;
    }
  }

  @media screen and (max-width: ${constants.breakpoints.md}px) {
    .idle-container {
      transform: none;
    }

    .idle-content {
      flex-wrap: wrap;

      & > * {
        width: 100% !important;
      }
    }

    .highscore {
      margin-top: 3rem;
      margin-left: ${constants.misc.screenContainerMarginX};
      margin-right: ${constants.misc.screenContainerMarginX};
    }
  }
`;

export default StyledPresentationScreen;
