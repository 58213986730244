//@flow
export default {
  xs: '0.25rem',
  sm: '0.75rem',
  base: '0.9rem',
  md: '1.25rem',
  lg: '1.5rem',
  xl: '1.75rem',
  xxl: '2.5rem',
  xxxl: '3rem'
};
