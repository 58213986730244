//@flow
import {
  SET_LANG,
  ADD_POINTS,
  CLEAR,
  SET_EMAIL,
  SET_TREE,
  RESET_POINTS,
  type AddPointsAction,
  type SetEmailAction,
  type ClearAction,
  type SetTreeAction,
  SET_USERNAME,
  SET_QUIZ
} from '../types/ReduxTypes';

export const setLang = lang => {
  return { type: SET_LANG, lang };
};

export const setQuiz = (quiz: Object): SetQuizAction => {
  return { type: SET_QUIZ, quiz };
};

export const addPoints = (points: number): AddPointsAction => {
  return { type: ADD_POINTS, points };
};

export const resetPoints = (points: number) => {
  return { type: RESET_POINTS };
};

export const setTree = (tree: string): SetTreeAction => {
  return { type: SET_TREE, tree };
};

export const setEmail = (email: string): SetEmailAction => {
  return { type: SET_EMAIL, email };
};

export const setUsername = (username: string): SetUsernameAction => {
  return { type: SET_USERNAME, username };
};
export const clear = (): ClearAction => {
  return { type: CLEAR };
};
