import React from 'react';
import styled from 'styled-components';
import Logotype from '../logo';
import constants from '../../constants';

const Header = ({ className }) => (
  <div className={className}>
    <Logotype />
  </div>
);

export default styled(Header)`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2;
  padding: ${constants.misc.screenContainerMarginX};
`;
