import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';

let locale = {};
export function setLocale(localeObj) {
  locale = localeObj;
}

function mapStateToProps({ lang }) {
  if (locale[lang] === undefined) lang = 'sv';

  return {
    locale: lang,
    messages: locale[lang] || {}
  };
}

export default connect(mapStateToProps)(IntlProvider);
