//@flow
import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import Caption from '../text/Caption';
import constants from '../../constants';

const StyledInput = styled.input.attrs({
  type: 'email'
})`
  width: 100%;
  background: transparent;
  border: none;
  outline: none;
  &::placeholder {
    color: ${constants.colors.gray60};
  }
  border-bottom: 1px solid ${constants.colors.brand.primary};
  padding: ${constants.spacing.xs} 0;
  color: ${constants.colors.brand.white};
`;

const EmailInput = ({ className, onChange, value, placeholder }) => (
  <div className={className}>
    <label htmlFor="email_input">
      <Caption color={constants.colors.brand.primary}>
        <FormattedMessage id="input_email" />
      </Caption>
    </label>
    <StyledInput
      id="email_input"
      onChange={onChange}
      value={value}
      placeholder={placeholder}
    />
  </div>
);

export default styled(EmailInput)`
  ${Caption} {
    display: flex;
  }
`;
