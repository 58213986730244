import React from 'react';
import styled, { keyframes } from 'styled-components';
import constants from '../../constants';

const Loading = ({ className }) => (
  <div className={className}>
    <div className="spinner" role="alert" aria-busy="true"></div>
  </div>
);

const spinnerAnimation = keyframes`
  from { transform: rotateZ(0deg) }
  to { transform: rotateZ(360deg) }
`;

const fadeIn = keyframes`
  from { opacity: 0 }
  to { opacity: 1 }
`;

export default styled(Loading)`
  min-height: 20vh;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .spinner {
    display: inline-block;
    width: 5rem;
    height: 5rem;
    vertical-align: text-bottom;
    border: 0.4rem solid ${constants.colors.brand.primary};
    border-right-color: transparent;
    border-radius: 50%;
    opacity: 0;
    animation: ${spinnerAnimation} 0.75s linear infinite,
      ${fadeIn} 0.4s ease forwards 0.2s;
  }
`;
