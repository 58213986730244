//@flow
import React from 'react';
import styled, { type ReactComponentStyled } from 'styled-components';
import * as R from 'ramda';
import Dot from './dot';
type Props = {
  steps: number,
  currentStep: number,
  className: ?string
};

const CenterContainer = styled.div`
  display: flex;
`;

const QuestionIndicator = (props: Props) => (
  <div className={props.className}>
    <CenterContainer>
      {R.map(
        step => (
          <Dot
            key={step}
            active={props.currentStep - 1 === step}
            completed={props.currentStep > step + 1}
          />
        ),
        R.range(0, props.steps)
      )}
    </CenterContainer>
  </div>
);

const StyledQuestionIndicator: ReactComponentStyled<Props> = styled(
  QuestionIndicator
)`
  display: flex;
  justify-content: center;
`;
StyledQuestionIndicator.defaultProps = {
  steps: 0,
  currentStep: 0
};
export default StyledQuestionIndicator;
