//@flow

import React from 'react';
import styled from 'styled-components';
import tempLogo from './logo.svg';

type Props = {
  title: string,
  className: ?string | ?Array<string>
};

const Logotype = ({ title, className }: Props) => (
  <span title={title} className={className}>
    <img src={tempLogo} alt={title} />
  </span>
);

export default styled(Logotype)`
  right: 0;
  display: block;
  margin-right: auto;
  width: 6rem;
`;
