import spacing from './constants/spacing';
import breakpoints from './constants/breakpoints';

export default {
  breakpoints: {
    xs: breakpoints.xs,
    sm: breakpoints.sm,
    md: breakpoints.md,
    lg: breakpoints.lg,
    xl: breakpoints.xl,
    mobile: breakpoints.mobile,
    tablet: breakpoints.tablet,
    desktop: breakpoints.desktop
  },
  spacing: {
    0: '0',
    1: spacing.xs,
    2: spacing.sm,
    3: spacing.md,
    4: spacing.base,
    5: spacing.lg,
    6: spacing.xl,
    7: spacing.xxl,
    8: spacing.xxxl
  }
};
