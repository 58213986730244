//@flow
import React from 'react';
import styled from 'styled-components';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import JSConfetti from 'js-confetti';

import { clear } from '../action_creators';
import constants from '../constants';
import ScreenContainer from '../components/layout/ScreenContainer';
import ScreenContent from '../components/layout/ScreenContent';
import ScreenStickyBottom from '../components/layout/ScreenStickyBottom';
import { Padding } from 'styled-components-spacing';
import H3 from '../components/text/H3';
import H4 from '../components/text/H4';
import H1 from '../components/text/H1';
import Body from '../components/text/Body';
import Caption from '../components/text/Caption';
import Button from '../components/button';
import TreeAnimation from '../components/tree_animation';

import { type RouterHistory } from 'react-router-dom';
import { fetchEndpoint } from '../api';
import { getQueryParam } from '../utils';

type PropTypes = {
  history: RouterHistory,
  points: number,
  tree: string,
  clear: () => *,
  className: string
};

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = { comparison: null };
    const quiz_id = Number(getQueryParam('quiz'));
    fetchEndpoint(
      `/results.json?quiz_id=${quiz_id}&points=${props.points}`
    ).then(({ result_in_percent }) => {
      this.setState({ comparison: result_in_percent });
    });
  }

  render() {
    const { points, className, location } = this.props;
    let comparison;

    if (this.state.comparison > 0)
      comparison = (
        <Padding top={2}>
          <H4>
            <FormattedHTMLMessage
              id="results_comparison"
              values={{ percentage: this.state.comparison }}
            />
          </H4>
        </Padding>
      );

    const jsConfetti = new JSConfetti();

    if (this.props.points) jsConfetti.addConfetti();

    return (
      <div className={className}>
        <div className="row">
          <div className="col">
            <H3>
              {points > 0 ? (
                <FormattedMessage id="results_title_good" values={{ points }} />
              ) : (
                <FormattedMessage id="results_title_bad" values={{ points }} />
              )}
            </H3>
            {comparison}
            <Padding top={2} bottom={5}>
              <Body>
                <FormattedHTMLMessage tagName="p" id="results_body" />
              </Body>
            </Padding>
          </div>
        </div>
      </div>
    );
  }
}

const StyledHeader = styled(Header)``;

const Results = ({ points, className, tree }) => (
  <div className={className}>
    <div className="row">
      <div className="col">
        <Caption color={constants.colors.brand.primary}>
          <FormattedMessage id="question_your_points" />
        </Caption>
        <H1>{points}</H1>
      </div>
    </div>
    <div className="row justify-content-center">
      <div className="col-6">
        <TreeAnimation name={tree} />
      </div>
    </div>
  </div>
);
const StyledResults = styled(Results)``;

const ButtonRow = ({ history, clear, className, location }) => (
  <div className={className}>
    <div className="row">
      <div className="col-5">
        <Button
          onClick={() => {
            clear();
            history.push(`/${location.search}`);
          }}
          title={<FormattedMessage id="results_restart_button" />}
        />
      </div>
      <div className="col-7">
        <Button
          onClick={() => {
            history.push(`/details/${location.search}`);
          }}
          title={<FormattedMessage id="results_compete_button" />}
        />
      </div>
    </div>
  </div>
);

const StyledButtonRow = styled(ButtonRow)``;

const ResultsScreen = ({
  className,
  history,
  clear,
  points,
  location,
  tree
}: PropTypes) => (
  <ScreenContainer className={className}>
    <ScreenContent>
      <StyledHeader points={points} />
      <StyledResults points={points} tree={tree} />
    </ScreenContent>
    <ScreenStickyBottom>
      <StyledButtonRow history={history} location={location} clear={clear} />
    </ScreenStickyBottom>
  </ScreenContainer>
);

const StyledResultsScreen = styled(ResultsScreen)``;

const mapStateToProps = s => ({
  points: s.points,
  tree: s.tree
});

const mapDispatchToProps = (dispatch: *) =>
  bindActionCreators(
    {
      clear
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StyledResultsScreen);
