//@flow
import React from 'react';
import styled, { type ReactComponentStyled } from 'styled-components';
import { transparentize } from 'polished';
import constants from '../../constants';

type Props = {
  percentage: number,
  className: ?string
};

const width = 200;
const strokeWidth = 40;
const r = width / 2 - strokeWidth / 2;
const c = Math.PI * (r * 2);

const calculateBar = (percentage: number) => {
  const result = Math.max(0, Math.min(100, percentage), percentage);
  return ((100 - result) / 100) * c;
};

const Track = styled.circle.attrs({
  cx: width / 2,
  cy: width / 2,
  fill: 'transparent',
  r: r,
  strokeDasharray: c,
  strokeDashoffset: 0
})`
  stroke: ${transparentize(0.5, constants.colors.brand.primary)};
  stroke-width: ${strokeWidth};
`;

const Bar = Track.extend.attrs({
  strokeDashoffset: (p: Props) => calculateBar(p.percentage)
})`
  stroke: #fff;
  transition: stroke-dashoffset 100ms linear;
`;

const Svg = styled.svg.attrs({
  version: '1.1',
  viewBox: `0 0 ${width} ${width}`,
  xmlns: 'http://www.w3.org/2000/svg'
})`
  transform: rotate(270deg) scaleY(-1);
  transform-origin: 50% 50%;
  width: 2rem;
  height: 2rem;
`;

const Countdown = (props: Props) => (
  <div className={props.className}>
    <Svg>
      <Track />
      <Bar percentage={props.percentage} />
    </Svg>
  </div>
);

const StyledButton: ReactComponentStyled<Props> = styled(Countdown)`
  display: inline-block;
`;

export default StyledButton;
