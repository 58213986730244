import React from 'react';
import styled from 'styled-components';

import Body from '../text/Body';

class Row extends React.Component {
  render() {
    const { rank, entry, className } = this.props;
    let flag;

    if (entry.country) {
      try {
        // The try/catch prevents errors if flag doesn't exist
        const flagImg = require(`../../images/flags/${entry.country}.png`);
        if (flagImg)
          flag = (
            <div>
              <img alt="Player flag" src={flagImg} />
            </div>
          );
      } catch (e) {}
    }

    return (
      <tr className={className}>
        <td>
          <Body>{rank}.</Body>
        </td>
        <td className="player-flag">{flag}</td>
        <td className="player-name">
          <Body>{entry.name}</Body>
        </td>
        <td>
          <Body>{entry.points}</Body>
        </td>
      </tr>
    );
  }
}

const StyledRow = styled(Row)`
  span {
    vertical-align: middle;
  }

  .player-flag {
    width: 1.5rem;
    padding-right: 0.25rem;

    div {
      overflow: hidden;
      width: 1.5rem;
      position: relative;
      height: 1em;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      max-height: 1rem;
      position: absolute;
    }
  }

  .player-name span {
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 40vw;
    overflow: hidden;
    display: inline-block;
  }
`;

export default StyledRow;
