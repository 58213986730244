import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import { unregister } from './registerServiceWorker';
import 'bootstrap/dist/css/bootstrap-reboot.min.css';
import 'bootstrap/dist/css/bootstrap-grid.min.css';
import './index.css';

ReactDOM.render(<App />, document.getElementById('root'));

// registerServiceWorker();
// Remove potential service worker
unregister();
