//@flow
import styled from 'styled-components';
import constants from '../../constants';
import { transparentize } from 'polished';
type Props = {
  active: ?boolean,
  completed: ?boolean
};

const getBackground = (props: Props) => {
  if (props.active) return constants.colors.brand.white;
  if (props.completed) return constants.colors.brand.primary;
  return transparentize(0.5, constants.colors.brand.primary);
};

const StyledButton = styled.div`
  border-radius: 50%;
  height: ${constants.spacing.sm};
  width: ${constants.spacing.sm};
  background: ${getBackground};
  margin: ${constants.spacing.xs};
`;

export default StyledButton;
