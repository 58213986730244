//@flow
import spacing from './spacing';

export default {
  borderRadiusSm: '3px',
  borderRadius: '5px',
  headerOffsetPadding: '5rem',
  screenContainerMarginX: spacing.lg,
  containerMaxWidth: '30rem'
};
