//@flow
import spacing from './spacing';
import colors from './colors';
import typography from './typography';
import shadows from './shadows';
import breakpoints from './breakpoints';
import zindex from './zindex';
import misc from './misc';

export default {
  spacing,
  colors,
  typography,
  shadows,
  breakpoints,
  zindex,
  misc
};
