//@flow
const xs = 0;
const sm = 576;
const md = 768;
const lg = 992;
const xl = 1200;

export default {
  xs: xs,
  sm: sm,
  md: md,
  lg: lg,
  xl: xl,

  mobile: sm,
  tablet: md,
  desktop: lg
};
